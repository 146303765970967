
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { UserModule } from '../store'

@Component({
  name: 'UserResetPassword'
})
export default class extends Vue {
  userModule = getModule(UserModule, this.$store)

  formInteracted = false
  showPassword = false
  redirectTimer?: number

  @Ref() readonly form!: any

  formController = new FormController({
    initialModel: {
      password: ''
    },
    validationRules: {
      password: [
        ...validationRules.exists,
        ...validationRules.password
      ]
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      const userId = this.$route.query.user as string | undefined
      const token = this.$route.query.token as string | undefined

      if (!userId) {
        throw new Error('User was not found')
      }
      if (!token) {
        throw new Error('Reset token was not found')
      }

      await this.userModule.userResetPassword({
        password: model.password,
        token,
        userId
      })

      analytics.userResetPassword.track(this)
    }
  }

  mounted () {
    analytics.userResetPasswordScreen.track(this)

    this.formController.on('success', () => {
      this.formController.resetModel()
      this.redirectTimer = window.setTimeout(() => {
        this.$router.push({
          name: 'user-login'
        })
      }, 1000)
    })
  }

  destroyed () {
    clearTimeout(this.redirectTimer)
  }

  formInteraction () {
    if (!this.formInteracted) {
      this.formInteracted = true

      analytics.userResetPasswordForm.track(this)
      analytics.userResetPassword.time()
    }
  }
}
